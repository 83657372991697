import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MustMatch } from "../_helpers/must-match.validator";
import { AuthService } from "../shared/service/auth.service";
import { MatSnackBar } from "@angular/material";
import { ModeratorService } from '../shared/service/moderator.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"]
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  model: any = {};
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private user: ModeratorService,
    private router: Router
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        //email: ["", Validators.required]
        name: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", Validators.required],
        termsAndConditions: [false, [Validators.required]],
      },
      {
        validator: MustMatch("password", "confirmPassword")
      }
    );
  }
  get f() {
    return this.registerForm.controls;
  }
  onSubmit() {
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.submitted = true;
    var data = {
      'name': this.registerForm.value.name,
      'email': this.registerForm.value.email,
      'password': this.registerForm.value.password,
      'c_password': this.registerForm.value.password
    };
    this.user.signup(data).subscribe((resp: any) => {
      this.router.navigate(["verify-email"], { queryParams: { _q: resp.success.id } });
    },
      (error) => {
        this.submitted = false;
        console.log(error);
        if (error.status !== 422) {
          //this.handleHttpError(errorResponse);
        } else {
          let data = error.error;
          console.log(data);
          Object.keys(data.errors).forEach((fieldName) => {
            let msg = data.errors[fieldName][0];
            this.registerForm.controls[fieldName].setErrors({ 'backend': msg });
          });
          console.log(this.f.email.errors);
        }
      },
      () => {
        console.log('complete');
      });
    /*this.authService.afAuth.auth
      .createUserWithEmailAndPassword(
        this.registerForm.value.email,
        this.registerForm.value.password
      )
      .then(result => {
        // Call the SendVerificaitonMail() function when new user sign up and returns promise
        this.authService.SendVerificationMail();
        this.authService.SetUserData(result.user, true);
      })
      .catch(error => {
        this.submitted = false;
        this.snackBar.open(
          "The email address is already in use by another account.",
          "",
          {
            verticalPosition: "top",
            duration: 10000,
            panelClass: "snack-error"
          }
        );
      });*/
  }
}
