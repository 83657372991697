import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModeratorService } from '../shared/service/moderator.service';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {
  invalidToken = false;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private user : ModeratorService
  ) { }

  ngOnInit() {
    this.route.params.subscribe( query => {
     
      this.user.accountActivate(query.token).subscribe(
        (resp: any) => {
          this.router.navigate(['login']);
        }, 
        (error) => {
          this.invalidToken = true;
        }
      );


    })
  }

}
