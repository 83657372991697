import { Injectable } from "@angular/core";
import { ApiconfigService } from './apiconfig.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class BoardsService {

  constructor(private apiConfig: ApiconfigService, private http: HttpClient) { }

  addBoard(boardData) {
    return this.http.post(this.apiConfig.url + "/add-board", boardData, this.apiConfig.headers);
  }

  getBoards() {
    //get-boards
    return this.http.get(this.apiConfig.url + "/get-boards", this.apiConfig.headers);
  }

  deleteBoard(id) {
    return this.http.get(this.apiConfig.url + "/delete-board/" + id, this.apiConfig.headers);
  }
  getPollDetailsByBoardCode(id: string) {
    return this.http.get(this.apiConfig.url + "/moderator/poll-details/" + id, this.apiConfig.headers);
  }

  getBoardById(id: string) {
    return this.http.get(this.apiConfig.url + "/get-boards/" + id, this.apiConfig.headers);
  }

  getBoardDetails(code: string) {

    return this.http.get(this.apiConfig.url + "/moderator/board/" + code, this.apiConfig.headers);
  }

  saveSwotQuardant(card: any) {
    return this.http.post(this.apiConfig.url + "/moderator/board/swotquardant", card, this.apiConfig.headers);
  }

  getCardDetails(id) {

    return this.http.get(this.apiConfig.url + "/moderator/board/card/" + id, this.apiConfig.headers);
  }
  saveCard(card: any, id: any) {
    return this.http.post(this.apiConfig.url + "/moderator/board/card/" + id, card, this.apiConfig.headers);
  }

  deleteCard(id: any) {

    return this.http.delete(this.apiConfig.url + "/moderator/board/card/" + id, this.apiConfig.headers);
  }

  getModeratorCards(id: number) {
    return this.http.get(this.apiConfig.url + "/moderator/board/cards/" + id, this.apiConfig.headers);
  }

  startPoll(data: any) {
    return this.http.post(this.apiConfig.url + "/moderator/poll/start", data, this.apiConfig.headers);
  }
  endPoll(id: number) {
    return this.http.get(this.apiConfig.url + `/moderator/poll/stop/${id}`, this.apiConfig.headers);
  }

  addCard(data: any) {
    return this.http.post(this.apiConfig.url + "/moderator/card/add", data, this.apiConfig.headers);
  }
  getTemplateType(type: any) {
    var typeString: string = "NA";
    if (type == 1) {
      typeString = "Plain Board";
    } else if (type == 2) {
      typeString = "Pain & Gain";
    } else if (type == 3) {
      typeString = "SWOT";
    } else if (type == 4) {
      typeString = "Pros & Cons";
    }
    return typeString;
  }

  getExportData(code: number) {
    return this.http.get(this.apiConfig.url + `/moderator/board/export/${code}`, this.apiConfig.headers);
  }
}
