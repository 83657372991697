import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GuestComponent } from "./guest/guest.component";
import { MainComponent } from "./main/main.component";
import { HeaderComponent } from "./header/header.component";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../material.module";


@NgModule({
  declarations: [GuestComponent, MainComponent, HeaderComponent],
  exports: [GuestComponent, MainComponent, HeaderComponent],
  imports: [CommonModule, RouterModule.forChild([]), MaterialModule]
})
export class LayoutModule {}
