import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BoardsService } from 'src/app/shared/service/boards.service';


@Component({
  selector: 'pain-and-gain',
  templateUrl: './pain-and-gain.component.html',
  styleUrls: ['./pain-and-gain.component.scss']
})
export class PainAndGainComponent implements OnInit {

  @Input('board') boardModel: any;
  @Output('copy') copyEvt = new EventEmitter();
  
  constructor(
    private boardService: BoardsService
  ) { }

  ngOnInit() {
  }

}
