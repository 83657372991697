import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiconfigService {
  private httpHeaders: HttpHeaders;
  constructor() {
    const user = JSON.parse(localStorage.getItem("authuser"));
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + user.token
    });
  }
  get headers() {
    return {
      headers: this.httpHeaders
    };
  }
  get url() {
    console.log(environment);
    return environment.apiURL;
  }
}
