import { Injectable, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AlertComponent, alertData } from '../component/alert/alert.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmAlertService {

  private data: alertData = {
    title: '',
    message: '',
    noButton: 'No',
    yesButton: 'Yes'
  };

  constructor(private dialog: MatDialog) {
    this.data.title = "Confirmation!";
    this.data.message = "Are you sure you want to do this?";
  }

  openInfoModal() { }
  openWarningModal() { }
  openErrorModal() { }

  openConfirmModal(message = null): Observable<any> {
    return this.openModal();
  }

  private openModal(): Observable<any> {

    const dialogConfRef = this.dialog.open(AlertComponent, {
      width: "500px",
      disableClose: true,
      data: this.data

    });
    return dialogConfRef.afterClosed();
  }
  setTitle(title: string): this {
    this.data.title = title;
    return this;
  }
  setMessage(message: string): this {
    this.data.message = message;
    return this;
  }

}
