import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injectable, ErrorHandler, isDevMode } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MaterialModule } from "./material.module";
import { LoginComponent } from "./login/login.component";
import { LayoutModule } from "./layout/layout.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ForgetPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SignupComponent } from "./signup/signup.component";
import { HttpClientModule } from "@angular/common/http";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MustMatchDirective } from "./_helpers/must-match.directive";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { DetailsComponent } from "./board/details/details.component";
import { SwotComponent } from './board/swot/swot.component';
import { ProsAndConsComponent } from './board/pros-and-cons/pros-and-cons.component';
import { ModeratorService } from './shared/service/moderator.service';
import { ApiconfigService } from './shared/service/apiconfig.service';
import { PainAndGainComponent } from './board/pain-and-gain/pain-and-gain.component';
import { OpenPlayGroundComponent } from './board/open-play-ground/open-play-ground.component';
import { AccountActivationComponent } from './account-activation/account-activation.component';
import { MAT_DATE_LOCALE } from '@angular/material';

import * as Sentry from "@sentry/browser";
import { ConfirmAlertService } from './shared/service/confirm-alert.service';
import { AlertComponent } from './shared/component/alert/alert.component';
import { SlugifyPipe } from './shared/pipe/slugify.pipe';
import { ParticipantListComponent } from './shared/component/participant-list/participant-list.component';
import { BoardHeaderComponent } from './shared/component/board-header/board-header.component';
import { QRCodeModule } from 'angularx-qrcode';

Sentry.init({
  dsn: "https://c06904cc9e3f4c6294aba350676243d3@sentry.io/1458936",
  beforeSend(event, hint) {
    console.log(isDevMode());
    if (isDevMode()) {
      return null;
    }
    return event;
  }
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    console.log(isDevMode());
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    SignupComponent,
    MustMatchDirective,
    VerifyEmailComponent,
    DetailsComponent,
    SwotComponent,
    ProsAndConsComponent,
    PainAndGainComponent,
    OpenPlayGroundComponent,
    AccountActivationComponent,
    AlertComponent,
    SlugifyPipe,
    ParticipantListComponent,
    BoardHeaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HttpClientModule,
    DragDropModule,
    QRCodeModule
  ],
  exports: [SlugifyPipe],
  entryComponents: [AlertComponent],
  providers: [SlugifyPipe, ConfirmAlertService, ModeratorService, ApiconfigService, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, { provide: ErrorHandler, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent]
})
export class AppModule { }
