import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'participant-list',
  templateUrl: './participant-list.component.html',
  styleUrls: ['./participant-list.component.scss']
})
export class ParticipantListComponent implements OnInit {

  @Input('data') participants;
  @Input('selectedCard') allParticipantCards = []; // Used to store all checked Cards from the list for Import All features.

  
  constructor() { }

  ngOnInit() {
  }

  setParticipantCrads(event: any, card: any): void {

    if (this.allParticipantCards.includes(card) === true) {

      const participantId = this.allParticipantCards.indexOf(card);
      if (participantId > -1) {
        this.allParticipantCards.splice(participantId, 1);
      }

    } else {

      this.allParticipantCards.push(card);
    }

    //this.disableImport = (this.allParticipantCards.length > 0) ? false : true;


  }

  public trackByFunction(index: number, participant: any){
    if(!participant) return null;
    return participant.name;  //track by the name of the participant
  }

}
