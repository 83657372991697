import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from 'src/app/shared/service/auth.service';
import { MatSnackBar } from '@angular/material';
import { ModeratorService } from '../shared/service/moderator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from '../_helpers/must-match.validator';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  submitted: Boolean = false;
  success: Boolean = false;
  invalidToken = false;
  token = null;
  email = null;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private user: ModeratorService
  ) {}

  ngOnInit() {
    this.resetPassword = this.formBuilder.group(
      {
        password : ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword : ["", Validators.required]
      },
      {
        validator : MustMatch("password", "confirmPassword") 
      }
    );

    this.route.paramMap.pipe(
      switchMap((params) => {
        this.token = params.get('token');
        return this.user.verifyPasswordToken(this.token);
      })
    ).subscribe((resp: any) => {
      this.email = resp.email;
      console.log(this.email);
    }, error => {
      this.submitted = false;
      this.snackBar.open(
        error.error.message,
        "",
        {
          verticalPosition: "top",
          duration: 10000,
          panelClass: "snack-error"
        }
      );
    });
  }
  get f() {
    return this.resetPassword.controls;
  }
  onSubmit() {
    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }
    this.submitted = true;
    var data = {
      "email": this.email,
      "password": this.resetPassword.value.password,
      "password_confirmation": this.resetPassword.value.confirmPassword,
      "token": this.token
    };
    return this.user.resetPasswordByToken(data).subscribe((resp:any) => {
      this.submitted = true;
      this.snackBar.open(
        //resp.success.message,
        "Password reset successfully",
        "",
        {
          verticalPosition: "top",
          duration: 10000,
          panelClass: "snack-success"
        }
      );
      this.router.navigate(['/login']);
    }, (error) => {
      this.submitted = false;
      this.snackBar.open(
        error.error.message,
        "",
        {
          verticalPosition: "top",
          duration: 10000,
          panelClass: "snack-error"
        }
      );
    },() => {

    }
    );
  }
}
