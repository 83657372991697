import { Injectable, NgZone } from "@angular/core";

import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
  }
  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("authuser"));
    return user !== null && user.emailVerified !== false ? true : false;
  }
  get userId(): string {
    const user = JSON.parse(localStorage.getItem("authuser"));
    return user.id;
  }


  SignOut() {
    localStorage.removeItem("authuser");
    this.router.navigate(["login"]);
  }
}
