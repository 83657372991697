import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BoardsService } from 'src/app/shared/service/boards.service';
import { CdkDragDrop, moveItemInArray, CdkDrag, CdkDropList, transferArrayItem } from '@angular/cdk/drag-drop';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import html2canvas from 'html2canvas';
import { SlugifyPipe } from 'src/app/shared/pipe/slugify.pipe';
import { BehaviorSubject, Subject } from 'rxjs';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

declare const download: any;

@Component({
  selector: 'open-play-ground',
  templateUrl: './open-play-ground.component.html',
  styleUrls: ['./open-play-ground.component.scss']
})
export class OpenPlayGroundComponent implements OnInit {

  
  @Input('board') boardModel: any;
  @Output('copy') copyEvt = new EventEmitter();
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  boardModelSub = new BehaviorSubject<boolean>(false);
  boardModelLoaded$ = this.boardModelSub.asObservable();
  loadingBarSub = new BehaviorSubject<boolean>(true);
  loadingBar$ = this.loadingBarSub.asObservable();

  public isFirstTimeLoad: boolean = true;

  lastC = 0;
  lastR = 0;

  allParticipantCards = []; // Used to store all checked Cards from the list for Import All features.
  disableImport = true;
  participants = [];
  panelOpenState = false;

  disabledCSVButton: boolean = false;

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    showLabels: true,
    showTitle: false,
    //title: 'SWOT:',
    useBom: true,
    noDownload: false,
    //headers: ["Card Name", "Quadrant", "User"]
  };

  tableGrid = [];
  isLoadedQudrant = false;
  hideEditIcon = false;
  public showResult = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private route: ActivatedRoute,
    private boardService: BoardsService,
    private slugifyPipe: SlugifyPipe
  ) { }
  setLastCellLoc(c, r) {
    this.lastC = c;
    this.lastR = r;
    console.log(this.lastC + " - " + this.lastR);
  }
  
 ngOnInit() {
  this.route.params.subscribe(query => {
    this.initBoardData();
  });
  IntervalObservable.create(20000)
    .pipe(takeUntil(this.destroy$))
    .subscribe(response => {
      this.refreshContent();
    });
}

initBoardData(){
  var backToShowResult: any = localStorage.getItem(`show-result-${this.boardModel.code}`);

  if (backToShowResult != null && backToShowResult == this.boardModel.code) {
    this.showResult = true;
    localStorage.removeItem(`show-result-${this.boardModel.code}`);

  }

  this.boardService.getBoardDetails(this.boardModel.code).subscribe(
    (resp: any) => {
      this.participants = resp.participants;

    },
    (error) => {
      console.log(error);
    }
  );

  this.boardService.getModeratorCards(this.boardModel.id).subscribe(
    (resp: any) => {

      this.tableGrid = resp.tableGrid;
      this.isLoadedQudrant = true;

    },
    (error) => {
      console.log(error);
    }
  );
}

refreshContent() {
  this.isFirstTimeLoad = false;
  this.initBoardData();
}


  drop(event: CdkDragDrop<string[]>, col: number, cell: number) {


    if (event.previousContainer !== event.container) {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      this.updateAttributes(event.container.data, event.container.id, col, cell);
    }



  }

  updateAttributes(card: any, card_type: string, col: number, cell: number) {

    var q_type = 'all';

    var lastColRow = this.tableGrid[0].lastCol.toString() + this.tableGrid[0].lastRow;
    var currentColCell = (col.toString() + cell);
    card[0].position = col + '_' + cell;
    card[0].cell_position = col + '_' + cell; // For update in API

    if (lastColRow <= currentColCell) {

      this.tableGrid[0].lastCol = col;
      this.tableGrid[0].lastRow = cell + 1;

    }
    this.increaseOrRemoveColumn(this.tableGrid[0]);



    var currentCardQuardrant = (card[0].type === undefined) ? card[0].quadrant : card[0].type;


    card[0].quadrant = q_type;
    card[0].type = q_type;
    card[0].board_id = this.boardModel.id;
    card[0].participant_card_id = (card[0].participant_card_id === undefined) ? card[0].id : card[0].participant_card_id;

    card.forEach(async (item) => {

      await this.boardService.saveSwotQuardant(item).subscribe(
        (resp: any) => {
          console.log(resp);
        },
        (error) => {
          console.log('error');
        }
      );

    })

  }

  addToBoard(card: any) {
    card.participant_card_id = (card.participant_card_id === undefined) ? card.id : card.participant_card_id;
    if (!card.isExistsInBoard) {

      card.isExistsInBoard = true;

      var pos = card.position;
      var parsePosition = pos.split('_');
      card.col_no = parsePosition[0];
      this.tableGrid[0].rowData[parsePosition[0]].list[parsePosition[1]].item.push(card);

      var cardData = {
        board_id: this.boardModel.id,
        name: card.name,
        quadrant: card.type,
        color: card.color,
        participant_card_id: card.id,
        cell_position: card.position

      };
      this.boardService.saveSwotQuardant(cardData).subscribe(
        (resp: any) => {
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );

    }



  }

  importToBoard(): void {
    var allC = this.tableGrid[0].lastCol,
      allR = this.tableGrid[0].lastRow;

    this.allParticipantCards.forEach(async (item) => {

      if (item.isExistsInBoard === false) {

        item.position = allC + '_' + allR;
        this.increaseOrRemoveColumn(this.tableGrid[0]);
        allR++;
        this.tableGrid[0].lastRow = allR;

        if ((this.tableGrid[0].lastRow) % 4 == 0) {
          allC++;
          this.tableGrid[0].lastCol = allC;
          allR = 0;
          this.tableGrid[0].lastRow = allR;

        }

      }

      await this.addToBoard(item);
    });
    this.increaseOrRemoveColumn(this.tableGrid[0]);
  }

  massImportToBoard(): void {
    this.allParticipantCards = [];
    this.storeToParticipantCards();
    this.importToBoard();

  }

  storeToParticipantCards() {
    this.participants.forEach(async (participant) => {

      participant.cards.forEach(async (card) => {

        await this.allParticipantCards.push(card);

      });

    });
  }

  exportToCSV() {
    this.disabledCSVButton = true;
    this.boardService.getExportData(this.boardModel.code).subscribe((resp: any) => {
      this.disabledCSVButton = false;
      new AngularCsv(resp.data, resp.file_name, this.csvOptions);
    }, (error) => {
      this.disabledCSVButton = false;
    });
  }

  isDroppable(drag: CdkDrag, drop: CdkDropList) {

    return (drop.data.length === 0) ? true : false;

  }

  increaseOrRemoveColumn(data: any) {
    let emptyColumnObject = {
      list: [
        { item: [] },
        { item: [] },
        { item: [] },
        { item: [] }
      ]
    };
    var itemPerCol = [];
    let impData = data.rowData.slice();
    impData.reverse().map((column) => {
      var _len = 0;
      column.list.forEach((cur, idx) => {
        _len += cur.item.length;
      });
      itemPerCol.push(_len);
    });

    var blankCol = 0;
    if (itemPerCol.length > 2) {
      for (var i = 0; i < (itemPerCol.length - 2); i++) {
        if (itemPerCol[i] == 0) {
          blankCol++;
        } else {
          break;
        }
      }
    }
    if (blankCol == 0) {
      data.rowData.push(emptyColumnObject);
    } else if (blankCol > 1) {
      data.rowData.splice(data.rowData.length - blankCol + 1);
    }
    /*
        if ((maxCol - 1) === currentCol) {
          data.maxCol = maxCol + 1;
          data.rowData.push(emptyColumnObject);
        } else {
          //Check for remove Column
          if (data.rowData.length >= 4) {
    
            var lastTwoConsequtiveCol = data.rowData.slice(data.rowData.length - 2);
    
            var CheckElementArr = lastTwoConsequtiveCol.map((col) => {
              return col.list.filter((r) => { return r.item.length > 0 })
            });
    
            let removableCoulmnCount = 1;
    
            if ((CheckElementArr[0].length === 0) && (CheckElementArr[1].length === 0)) {
    
              if (data.rowData.length > 4) removableCoulmnCount = ((data.maxCol - 1) - currentCol) - 1;
              console.log(data.rowData);
              for (let i = 0; i < removableCoulmnCount; i++) {
                data.rowData.splice(data.rowData.length - 1, 1);
                data.maxCol = (maxCol - i) - 1;
              }
    
            }
    
          }
    
    
        }*/

  }

  downloadImage() {
    this.hideEditIcon = true;
    setTimeout(() => {
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        // this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        // this.downloadLink.nativeElement.download = this.slugifyPipe.transform(this.boardModel.name) + '.png';
        // this.downloadLink.nativeElement.click();
        download(canvas.toDataURL('image/png'), this.slugifyPipe.transform(this.boardModel.name) + '.png', "image/png");
        this.hideEditIcon = false;
      });
    }, 0);
  }
}
