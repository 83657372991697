import { NgModule } from "@angular/core";
import {
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatMenuModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatGridListModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSortModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatTableModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatListModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatRadioModule,
  MatBadgeModule

} from "@angular/material";
import { BoardCodeComponent, DialogBoardCode } from './shared/component/board-code/board-code.component';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';


@NgModule({
  declarations: [
    BoardCodeComponent,
    DialogBoardCode

  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSidenavModule,
    MatGridListModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTableModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatListModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatBadgeModule,
    BoardCodeComponent
  ],
  imports: [
    MatIconModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    QRCodeModule

  ],
  entryComponents: [DialogBoardCode]
})
export class MaterialModule { }
