import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { GuestGuard } from "./shared/guest.guard";
import { Layouts } from "../app/app.component";
import { SignupComponent } from "./signup/signup.component";
import { ForgetPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ModeratorGuard } from "./shared/moderator.guard";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { DetailsComponent } from "./board/details/details.component";
import { AccountActivationComponent } from './account-activation/account-activation.component';



const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    data: { layout: Layouts.GuestLayout },
    canActivate: [GuestGuard]
  },
  {
    path: "signup",
    component: SignupComponent,
    data: { layout: Layouts.GuestLayout },
    canActivate: [GuestGuard]
  },
  {
    path: "forgot-password",
    component: ForgetPasswordComponent,
    data: { layout: Layouts.GuestLayout },
    canActivate: [GuestGuard]
  },
  {
    path: "password/reset/:token",
    component: ResetPasswordComponent,
    data: { layout: Layouts.GuestLayout },
    canActivate: [GuestGuard]
  },
  {
    path: "verify-email",
    component: VerifyEmailComponent,
    data: { layout: Layouts.GuestLayout },
    canActivate: [GuestGuard]
  },
  {
    path: "board",
    loadChildren: "./board/board.module#BoardModule",
    //pathMatch: "full",
    data: { layout: Layouts.MainLayout },
    canActivate: [ModeratorGuard]
  },
  {
    path: "board-details/:id",
    component: DetailsComponent,
    //pathMatch: "full",
    data: { layout: Layouts.MainLayout },
    canActivate: [ModeratorGuard]
  },
  {
    path: "activate/:token",
    component: AccountActivationComponent,
    //pathMatch: "full",
    data: { layout: Layouts.GuestLayout },
    canActivate: [GuestGuard]
  }
  /*,
 {
   path: "board-details/:id",
   component: DetailsComponent,
   //pathMatch: "full",
   data: { layout: Layouts.MainLayout },
   canActivate: [ModeratorGuard]
 }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
