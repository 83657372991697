import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, transferArrayItem, CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { BoardsService } from 'src/app/shared/service/boards.service';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { SlugifyPipe } from 'src/app/shared/pipe/slugify.pipe';

import { BehaviorSubject, Subject } from 'rxjs';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

declare const download: any;

@Component({
  selector: 'swot-board',
  templateUrl: './swot.component.html',
  styleUrls: ['./swot.component.scss']
})
export class SwotComponent implements OnInit {
  @Input('board') boardModel: any;

  @Output('copy') copyEvt = new EventEmitter();
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  boardStrengths = [];
  boardWeakness = [];
  boardOpportunity = [];
  boardThreats = [];
  allParticipantCards = []; // Used to store all checked Cards from the list for Import All features.
  disableImport = true;
  hideEditIcon = false;

  public isFirstTimeLoad: boolean = true;

  tableGrid = [];
  isLoadedQudrant = false;



  //API: All Participants respect to the current board id 
  participants = [];

  panelOpenState = false;

  cardsData: any;
  disabledCSVButton: boolean = false;


  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    showLabels: true,
    showTitle: false,
    //title: 'SWOT:',
    useBom: true,
    noDownload: false,
    //headers: ["Card Name", "Quadrant", "User"]
  };

  public showResult = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private route: ActivatedRoute,
    private boardService: BoardsService,
    public router: Router,
    private slugifyPipe: SlugifyPipe
  ) { }


  ngOnInit() {
    this.route.params.subscribe(query => {
      this.initBoardData();
    });
    IntervalObservable.create(20000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.refreshContent();
      });
  }

  initBoardData(){
    console.log(this.boardModel);

    var backToShowResult: any = localStorage.getItem(`show-result-${this.boardModel.code}`);

    if (backToShowResult != null && backToShowResult == this.boardModel.code) {
      this.showResult = true;
      localStorage.removeItem(`show-result-${this.boardModel.code}`);

    }
    this.boardService.getBoardDetails(this.boardModel.code).subscribe((resp: any) => {
      this.participants = resp.participants;
    },
      (error) => {
        console.log(error);
      }
    );

    this.boardService.getModeratorCards(this.boardModel.id).subscribe((resp: any) => {
      this.tableGrid = resp.tableGrid;
      this.isLoadedQudrant = true;
    },
      (error) => {
        console.log(error);
      }
    );
  }

  refreshContent() {
    this.isFirstTimeLoad = false;
    this.initBoardData();
  }

  drop(event: CdkDragDrop<string[]>, col: number, cell: number) {

    if (event.previousContainer === event.container) {
      /*console.log("Same Container");
      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);*/
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.updateAttributes(event.container.data, event.container.id, event.previousContainer.id, col, cell);
    }

  }

  addToBoard(card: any) {
    card.participant_card_id = (card.participant_card_id === undefined) ? card.id : card.participant_card_id;
    if (card.type === 'strength' && !card.isExistsInBoard) {

      card.isExistsInBoard = true;

      var pos = card.position;
      var parsePosition = pos.split('_');
      card.col_no = parsePosition[0];
      this.tableGrid[0].rowData[parsePosition[0]].list[parsePosition[1]].item.push(card);

      //Scope for optimize the below code
      var cardData = {
        board_id: this.boardModel.id,
        name: card.name,
        quadrant: card.type,
        color: card.color,
        participant_card_id: card.id,
        cell_position: card.position

      };
      this.boardService.saveSwotQuardant(cardData).subscribe(
        (resp: any) => {
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );

    } else if (card.type === 'weakness' && !card.isExistsInBoard) {

      card.isExistsInBoard = true;

      var pos = card.position;
      var parsePosition = pos.split('_');
      card.col_no = parsePosition[0];
      this.tableGrid[1].rowData[parsePosition[0]].list[parsePosition[1]].item.push(card);

      //Scope for optimize the below code
      var cardData = {
        board_id: this.boardModel.id,
        name: card.name,
        quadrant: card.type,
        color: card.color,
        participant_card_id: card.id,
        cell_position: card.position

      };
      this.boardService.saveSwotQuardant(cardData).subscribe(
        (resp: any) => {
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );

    } else if (card.type === 'opportunity' && !card.isExistsInBoard) {

      card.isExistsInBoard = true;
      var pos = card.position;
      var parsePosition = pos.split('_');
      card.col_no = parsePosition[0];
      this.tableGrid[2].rowData[parsePosition[0]].list[parsePosition[1]].item.push(card);

      //Scope for optimize the below code
      var cardData = {
        board_id: this.boardModel.id,
        name: card.name,
        quadrant: card.type,
        color: card.color,
        participant_card_id: card.id,
        cell_position: card.position

      };
      this.boardService.saveSwotQuardant(cardData).subscribe(
        (resp: any) => {
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );

    } else if (card.type === 'threat' && !card.isExistsInBoard) {

      card.isExistsInBoard = true;
      var pos = card.position;
      var parsePosition = pos.split('_');
      card.col_no = parsePosition[0];
      this.tableGrid[3].rowData[parsePosition[0]].list[parsePosition[1]].item.push(card);
      //Scope for optimize the below code
      var cardData = {
        board_id: this.boardModel.id,
        name: card.name,
        quadrant: card.type,
        color: card.color,
        participant_card_id: card.id,
        cell_position: card.position

      };
      this.boardService.saveSwotQuardant(cardData).subscribe(
        (resp: any) => {
          console.log(resp);
        },
        (error) => {
          console.log(error);
        }
      );

    }



  }

  updateAttributes(card: any, card_type: string, prev_card_type: string, col: number, cell: number) {
    let previousQuadrantData = this.getPreviousQuadrantData(card_type, prev_card_type);
    var q_type = 'weakness';
    if (card_type.split('_')[0] == 'threat') {

      q_type = 'threat';
      var lastColRow = this.tableGrid[3].lastCol.toString() + this.tableGrid[3].lastRow;
      var currentColCell = (col.toString() + cell);
      card[0].position = col + '_' + cell;
      card[0].cell_position = col + '_' + cell; // For update in API

      if (lastColRow <= currentColCell) {

        this.tableGrid[3].lastCol = col;
        this.tableGrid[3].lastRow = cell + 1;

      }

      this.increaseOrRemoveColumn(this.tableGrid[3], previousQuadrantData);

    } else if (card_type.split('_')[0] == 'opportunity') {

      q_type = 'opportunity';
      var lastColRow = this.tableGrid[2].lastCol.toString() + this.tableGrid[2].lastRow;
      var currentColCell = (col.toString() + cell);
      card[0].position = col + '_' + cell;
      card[0].cell_position = col + '_' + cell; // For update in API

      if (lastColRow <= currentColCell) {

        this.tableGrid[2].lastCol = col;
        this.tableGrid[2].lastRow = cell + 1;

      }

      this.increaseOrRemoveColumn(this.tableGrid[2], previousQuadrantData);

    } else if (card_type.split('_')[0] == 'strength') {
      q_type = 'strength';

      var lastColRow = this.tableGrid[0].lastCol.toString() + this.tableGrid[0].lastRow;
      var currentColCell = (col.toString() + cell);
      card[0].position = col + '_' + cell;
      card[0].cell_position = col + '_' + cell; // For update in API

      if (lastColRow <= currentColCell) {

        this.tableGrid[0].lastCol = col;
        this.tableGrid[0].lastRow = cell + 1;

      }

      this.increaseOrRemoveColumn(this.tableGrid[0], previousQuadrantData);

    } else {
      //Weakness
      var lastColRow = this.tableGrid[1].lastCol.toString() + this.tableGrid[1].lastRow;
      var currentColCell = (col.toString() + cell);
      card[0].position = col + '_' + cell;
      card[0].cell_position = col + '_' + cell; // For update in API

      if (lastColRow <= currentColCell) {

        this.tableGrid[1].lastCol = col;
        this.tableGrid[1].lastRow = cell + 1;

      }

      this.increaseOrRemoveColumn(this.tableGrid[1], previousQuadrantData);

    }

    var currentCardQuardrant = (card[0].type === undefined) ? card[0].quadrant : card[0].type;

    this.checkPositionReset(currentCardQuardrant, card_type.split('_')[0]);

    card[0].quadrant = q_type;
    card[0].type = q_type;
    card[0].board_id = this.boardModel.id;
    card[0].participant_card_id = (card[0].participant_card_id === undefined) ? card[0].id : card[0].participant_card_id;

    card.forEach(async (item) => {
      //item.quadrant = q_type;
      await this.boardService.saveSwotQuardant(item).subscribe(
        (resp: any) => {
          console.log(resp);
        },
        (error) => {
          console.log('error');
        }
      );

    })

  }

  importToBoard(): void {
    var strengthC = this.tableGrid[0].lastCol,
      strengthR = this.tableGrid[0].lastRow,
      strengthIndex = 0,//(this.tableGrid[0].strengthIndex !== 0) ? this.tableGrid[0].strengthIndex : 0,

      weaknessC = this.tableGrid[1].lastCol, weaknessR = this.tableGrid[1].lastRow, weaknessIndex = 0,
      opportunityC = this.tableGrid[2].lastCol, opportunityR = this.tableGrid[2].lastRow, opportunityIndex = 0,
      threatC = this.tableGrid[3].lastCol, threatR = this.tableGrid[3].lastRow, threatIndex = 0;

    this.allParticipantCards.forEach(async (item, index) => {

      if (item.type === 'strength') {

        if (item.isExistsInBoard === false) {

          item.position = strengthC + '_' + strengthR;
          this.increaseOrRemoveColumn(this.tableGrid[0], false);
          strengthR++;
          this.tableGrid[0].lastRow = strengthR;

          if ((this.tableGrid[0].lastRow) % 4 == 0) {
            strengthC++;
            this.tableGrid[0].lastCol = strengthC;
            strengthR = 0;
            this.tableGrid[0].lastRow = strengthR;

          }
          strengthIndex++;
        }

      } else if (item.type === 'weakness') {

        if (item.isExistsInBoard === false) {

          item.position = weaknessC + '_' + weaknessR;
          this.increaseOrRemoveColumn(this.tableGrid[1], false);
          weaknessR++;
          this.tableGrid[1].lastRow = weaknessR;

          if ((this.tableGrid[1].lastRow) % 4 == 0) {
            weaknessC++;
            this.tableGrid[1].lastCol = weaknessC;
            weaknessR = 0;
            this.tableGrid[1].lastRow = weaknessR;
          }
          weaknessIndex++;
        }

      } else if (item.type === 'opportunity') {


        if (item.isExistsInBoard === false) {

          item.position = opportunityC + '_' + opportunityR;
          this.increaseOrRemoveColumn(this.tableGrid[2], false);
          opportunityR++;
          this.tableGrid[2].lastRow = opportunityR;


          if ((this.tableGrid[2].lastRow) % 4 == 0) {
            opportunityC++;
            this.tableGrid[2].lastCol = opportunityC;
            opportunityR = 0;
            this.tableGrid[2].lastRow = opportunityR;
          }
          opportunityIndex++;
        }

      } else if (item.type === 'threat') {

        if (item.isExistsInBoard === false) {

          item.position = threatC + '_' + threatR;
          this.increaseOrRemoveColumn(this.tableGrid[3], false);
          threatR++;
          this.tableGrid[3].lastRow = threatR;


          if ((this.tableGrid[3].lastRow) % 4 == 0) {
            threatC++;
            this.tableGrid[3].lastCol = threatC;
            threatR = 0;
            this.tableGrid[3].lastRow = threatR;
          }
          threatIndex++;
        }
      }
      await this.addToBoard(item);
    });

    for (var idx = 0; idx < 4; idx++) {
      this.increaseOrRemoveColumn(this.tableGrid[idx], false);
    }

  }

  massImportToBoard(): void {
    this.allParticipantCards = [];

    this.storeToParticipantCards();

    this.importToBoard();

  }

  storeToParticipantCards() {
    this.participants.forEach(async (participant) => {

      participant.cards.forEach(async (card) => {

        await this.allParticipantCards.push(card);

      });

    });
  }

  exportToCSV() {
    this.disabledCSVButton = true;
    this.boardService.getExportData(this.boardModel.code).subscribe((resp: any) => {
      this.disabledCSVButton = false;
      new AngularCsv(resp.data, resp.file_name, this.csvOptions);
    }, (error) => {
      this.disabledCSVButton = false;
    });
  }

  isDroppable(drag: CdkDrag, drop: CdkDropList) {
    return (drop.data.length === 0) ? true : false;
  }

  increaseOrRemoveColumn(data: any, previousQuadrantData: any) {
    let emptyColumnObject = {
      list: [
        { item: [] },
        { item: [] },
        { item: [] },
        { item: [] }
      ]
    };

    var itemPerCol = [];
    let impData = data.rowData.slice();
    impData.reverse().map((column) => {
      var _len = 0;
      column.list.forEach((cur, idx) => {
        _len += cur.item.length;
      });
      itemPerCol.push(_len);
    });

    var blankCol = 0;
    if (itemPerCol.length > 2) {
      for (var i = 0; i < (itemPerCol.length - 2); i++) {
        if (itemPerCol[i] == 0) {
          blankCol++;
        } else {
          break;
        }
      }
    }
    if (blankCol == 0) {
      data.rowData.push(emptyColumnObject);
    } else if (blankCol > 1) {
      data.rowData.splice(data.rowData.length - blankCol + 1);
    }
    if (previousQuadrantData !== false) {
      this.increaseOrRemoveColumn(previousQuadrantData, false);
    }

  }

  checkPositionReset(prevQuadrant: string, currentQuadrant: string) {
    let prevQuadrantData;

    if (prevQuadrant !== currentQuadrant) {

      if (prevQuadrant === 'strength') {

        prevQuadrantData = this.tableGrid[0];

      } else if (prevQuadrant === 'weakness') {

        prevQuadrantData = this.tableGrid[1];

      } else if (prevQuadrant === 'opportunity') {

        prevQuadrantData = this.tableGrid[2];

      } else {

        prevQuadrantData = this.tableGrid[3];

      }

      var findNonEmptyItem = prevQuadrantData.rowData.map((col) => { return col.list.filter((r) => { return r.item.length > 0 }) })
      var itemFound = false;
      for (let i = 0; i < findNonEmptyItem.length; i++) {
        if (findNonEmptyItem[i].length > 0) {
          itemFound = true;
          break;
        }
      }

      if (itemFound === false) {
        prevQuadrantData.lastCol = 0;
        prevQuadrantData.lastRow = 0;
      }

    }
  }

  boardGoTo(action: string) {
    switch (action) {
      case 'add_card':
        this.router.navigate(['/board/add-card', this.boardModel.code]);
        break;
    }
  }

  downloadImage() {
    this.hideEditIcon = true;
    setTimeout(() => {
      html2canvas(this.screen.nativeElement).then(canvas => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        //this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        //this.downloadLink.nativeElement.download = this.slugifyPipe.transform(this.boardModel.name) + '.png';
        //this.downloadLink.nativeElement.click();
        download(canvas.toDataURL('image/png'), this.slugifyPipe.transform(this.boardModel.name) + '.png', "image/png");
        this.hideEditIcon = false;
      });
    }, 0);
  }

  getPreviousQuadrantData(current_quadrant: string, previous_quadrant: string) {
    if (current_quadrant.split('_')[0] !== previous_quadrant.split('_')[0]) {

      if (previous_quadrant.split('_')[0] === 'strength') {
        return this.tableGrid[0];
      } else if (previous_quadrant.split('_')[0] === 'weakness') {
        return this.tableGrid[1];
      } else if (previous_quadrant.split('_')[0] === 'opportunity') {
        return this.tableGrid[2];
      } else {
        // Threat
        return this.tableGrid[3];
      }

    } else {
      return false;
    }
  }

}

