import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../shared/service/auth.service";
import { MatSnackBar } from "@angular/material";
import { ModeratorService } from '../shared/service/moderator.service';

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgetPasswordComponent implements OnInit {
  forgetPassword: FormGroup;
  submitted = false;
  success: Boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private user: ModeratorService
  ) {}

  ngOnInit() {
    this.forgetPassword = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }
  get f() {
    return this.forgetPassword.controls;
  }
  onSubmit() {
    // stop here if form is invalid
    if (this.forgetPassword.invalid) {
      return;
    }
    this.submitted = true;
    var data = {"email": this.forgetPassword.value.email };
    // Reset Forgot password
    this.user.forgotPassword(data).subscribe((resp:any) => {
      console.log(resp);
      this.success = true;

      this.snackBar.open(
        resp.success.message,
        "",
        {
          verticalPosition: "top",
          duration: 10000,
          panelClass: "snack-success"
        }
      );

    },
    (error) => {
      
      this.submitted = false;
      this.snackBar.open(
        error.error.message,
        "",
        {
          verticalPosition: "top",
          duration: 10000,
          panelClass: "snack-error"
        }
      );
    },
    () => {
      
    }
    );
    

    /*return this.authService.afAuth.auth
      .sendPasswordResetEmail(this.forgetPassword.value.email)
      .then(() => {
        this.success = true;
      })
      .catch(error => {
        this.submitted = false;
        this.snackBar.open(
          "There is no user record corresponding to this email!",
          "",
          {
            verticalPosition: "top",
            duration: 10000,
            panelClass: "snack-error"
          }
        );
      });*/
  }
}
