import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-guest-layout",
  templateUrl: "./guest.component.html",
  styleUrls: ["./guest.component.scss"]
})
export class GuestComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
