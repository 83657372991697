import { Component, OnInit } from "@angular/core";
import { Router, Data, RoutesRecognized } from "@angular/router";
import { AuthService } from "./shared/service/auth.service";

export enum Layouts {
  GuestLayout,
  MainLayout
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "Wiisdoo";
  Layouts = Layouts;
  layout: Layouts;
  constructor(private router: Router, public authService: AuthService) { }
  ngOnInit() {
    this.router.events.subscribe(data => {
      if (data instanceof RoutesRecognized) {
        if (this.authService.isLoggedIn) {
          this.layout = Layouts.MainLayout;
        } else {
          this.layout = Layouts.GuestLayout;
        }
      }
    });
  }
}
