import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'board-code',
  templateUrl: './board-code.component.html',
  styleUrls: ['./board-code.component.scss']
})

export class BoardCodeComponent implements OnInit {

  @Input('code') code;
  public codeFontSizeZoomIn = false;
  constructor(public dialog: MatDialog) { }
  @ViewChild('dialogOpenButton') dialogOpenButton;

  ngOnInit() {
  }
  openDialog(elm) {
    const dialogConfRef = this.dialog.open(DialogBoardCode, {
      disableClose: true,
      data: { code: this.code },
      
    });


    dialogConfRef.afterClosed().subscribe(() => {
      this.dialogOpenButton._elementRef.nativeElement.classList.remove('cdk-program-focused');
      this.dialogOpenButton._elementRef.nativeElement.classList.remove('cdk-focused');
    });
  }

}

@Component({
  selector: "dialog-board-code-box",
  template: `<div class="board-code-container">
              <p>Please enter the code below:</p>
              <h1>{{data.code}}</h1>
              <qrcode [qrdata]="'https://pwa.wiisdoo.com/search-board?code=' + data.code" [width]="296" [errorCorrectionLevel]="'M'"></qrcode>
              <div><button mat-raised-button color="primary" (click)="onClickBackToBoard()">Back to Board</button></div>
            </div>`,
  styleUrls: ['./board-code.component.scss']
})

export class DialogBoardCode {
  constructor(
    public dialogRef: MatDialogRef<DialogBoardCode>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
  ) { }

  ngOnInit() { }
  onClickBackToBoard() {
    this.dialogRef.close(this.data);
  }
}


export class QRCodeComponent {
  public myAngularxQrCode: string = null;
  constructor () {
    // assign a value
    this.myAngularxQrCode = 'Your QR code data string';
  }
}

