import { Component, OnInit } from "@angular/core";
import { AuthService } from "../shared/service/auth.service";
import { ModeratorService } from '../shared/service/moderator.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"]
})
export class VerifyEmailComponent implements OnInit {
  id: any;
  sendingReq: boolean = false;
  constructor(public user: ModeratorService, private route: ActivatedRoute, private snackBar: MatSnackBar) {
    this.route.queryParams.subscribe(params => {
      this.id = params['_q'];
      console.log(this.id);
    });
  }

  ngOnInit() { }
  SendVerificationMail() {
    this.sendingReq = true;
    this.user.resendVerification(this.id).subscribe(res => {
      console.log(res);
      this.sendingReq = false;
      this.snackBar.open("Email sent again!", "", {
        verticalPosition: "bottom",
        duration: 5000,
        panelClass: "msg-success"
      });
    },
      error => {
        console.log(error);
        this.sendingReq = false;
      },
      () => { })
  }
}
