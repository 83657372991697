import { Component, OnInit } from "@angular/core";
import { MatSidenav } from "@angular/material";
import { AuthService } from "src/app/shared/service/auth.service";
import { ModeratorService } from 'src/app/shared/service/moderator.service';

@Component({
  selector: "app-main-layout",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"]
})
export class MainComponent implements OnInit {
  constructor(public authService: AuthService, public user: ModeratorService) { }
  ngOnInit() { }

  participateInBoard(){
    window.open("https://pwa.wiisdoo.com", "_blank", "noopener")
  }
}
