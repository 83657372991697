import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModeratorService {
  private baseUrl: string = environment.apiURL;
  constructor(private http: HttpClient) { }
  signup(data) {
    console.log(data);
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.post(this.baseUrl + "/register/moderator", data, options);
  }

  resendVerification(id: number) {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.get(this.baseUrl + "/resend/verification-email/" + id, options);
  }
  login(email, password) {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.post(this.baseUrl + "/auth/login/moderator", { email: email, password: password }, options);
  }


  setToken(token) {
    localStorage.setItem("user", JSON.stringify(token));
    localStorage.setItem("authuser", JSON.stringify(token));
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("authuser"));
    console.log("isLoggedIn call:");
    console.log(user);
    return user !== null && user.emailVerified !== false ? true : false;
  }
  get userId(): string {
    const user = JSON.parse(localStorage.getItem("authuser"));
    return user.id;
  }
  get displayName(): string {
    const user = JSON.parse(localStorage.getItem("authuser"));
    return user.name;
  }
  get displayEmailVerified(): string {
    const user = JSON.parse(localStorage.getItem("authuser"));
    return user.emailVerified;
  }

  get displayEmail(): string {
    const user = JSON.parse(localStorage.getItem("authuser"));
    return user.email;
  }
  // Sign out
  SignOut() {
    localStorage.removeItem("authuser");
    //this.router.navigate(["login"]);
  }

  forgotPassword(data) {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.post(this.baseUrl + "/password/create", data, options);
  }

  accountActivate(token: string){

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };

    return this.http.get(this.baseUrl + "/signup/activate/" +token, options);
  }
  
  verifyPasswordToken(token: string) {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.get(this.baseUrl + "/password/reset/"+token, options);
  }

  resetPasswordByToken(data) {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    });
    let options = {
      headers: httpHeaders
    };
    return this.http.post(this.baseUrl + "/password/reset", data, options);
  }


}
