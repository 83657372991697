import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-board-header',
  templateUrl: './board-header.component.html',
  styleUrls: ['./board-header.component.scss']
})
export class BoardHeaderComponent implements OnInit {

  @Input('code') code;
  @Input('name') name;
  @Input('expiry_date') expiry_date;

  trimLimit = 35;
  constructor() { }

  ngOnInit() {
  }

}
