import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { AuthService } from "../shared/service/auth.service";
import { ModeratorService } from '../shared/service/moderator.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  model: any = {};
  formIsProcessing: Boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    public authService: AuthService,
    private user: ModeratorService
  ) { }

  ngOnInit() { }

  onSubmit() {
    this.formIsProcessing = true;
    this.user.login(this.model.email, this.model.password).subscribe((resp: any) => {
      console.log(resp);
      this.formIsProcessing = false;
      this.user.setToken(resp.success);
      window.location.href = "";
    }, error => {
      this.formIsProcessing = false;
      console.log(error);
      if (error.status !== 422) {
        //this.handleHttpError(errorResponse);
      } else {
        console.log(error.error.error);
        this.showError(error.error.error);
      }
    }, () => { });
  }
  showError(msg: string) {
    this.formIsProcessing = false;
    this.snackBar.open(msg, "", {
      verticalPosition: "top",
      duration: 10000,
      panelClass: "snack-error"
    });
  }
}
