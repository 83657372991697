import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BoardsService } from "src/app/shared/service/boards.service";
import { BehaviorSubject } from "rxjs";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"]
})
export class DetailsComponent implements OnInit {
  boardModelSub = new BehaviorSubject<boolean>(false);
  boardModelLoaded$ = this.boardModelSub.asObservable();
  loadingBarSub = new BehaviorSubject<boolean>(true);
  loadingBar$ = this.loadingBarSub.asObservable();
  boardModel: object;
  constructor(
    private route: ActivatedRoute,
    private board: BoardsService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    console.log(this.route.snapshot.params["id"]);
    this.route.params.subscribe(query => {
      this.loadingBarSub.next(true);
      this.board.getBoardById(query.id).subscribe((data: any) => {
        this.boardModel = data.boards;
        this.boardModelSub.next(true);
        this.loadingBarSub.next(false);
      }, error => {
        if (error.status == 500) {
          this.router.navigate(['/board']);
        }
      });
    });
  }

  copyToClipboard(item) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });

    document.execCommand("copy");
    this.snackBar.open("Code copied", "", {
      verticalPosition: "bottom",
      duration: 1000,
      panelClass: "snack-code-copy"
    });
  }
}
